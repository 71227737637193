<template>
  <b-overlay :show="loading" rounded="sm">
    <b-alert v-if="error" variant="danger" show class="m-2">
      <div class="alert-body">
        <span><strong>Error </strong> {{ error }}</span>
      </div>
    </b-alert>

    <b-card
      v-for="item in accounts"
      class="card-congratulation-medal"
      :key="item.id"
    >
      <b-row>
        <b-col cols="8">
          <h3>
            {{ item.name }}

            <span class="form-inline d-inline-block">
              <select
                class="form-control form-control-inline"
                @change="onStatusChange($event, item.id)"
              >
                <option value="true" :selected="item.active == true">
                  Active
                </option>
                <option value="false" :selected="item.active == false">
                  Disabled
                </option>
              </select>
            </span>
          </h3>
          <b-card-text class="font-small-3">
            {{ item.sellerId }}
          </b-card-text>

          Authorized Ago :
          <b-badge :variant="item.authorizedAgo > 330 ? 'danger' : 'success'">
            {{ item.authorizedAgo }} days
          </b-badge>
        </b-col>
        <b-col cols="4" class="text-end text-right">
          <router-link
            :to="{
              name: 'edit-amz-selling-partners',
              params: { accountId: item.id },
            }"
          >
            <b-button
              variant="primary"
              size="sm"
              class="btn-tour-finish mr-1 mb-1"
            >
              Edit
            </b-button>
          </router-link>

          <a class="text-danger" @click="remove(item.id)">Remove</a>
        </b-col>
      </b-row>

      <h4 class="mb-75 mt-2">
        Pages
      </h4>

      <router-link
        :to="{
          name: 'amz-selling-partners-logs',
          params: { accountId: item.id },
        }"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-tour-finish mr-1 mb-1"
        >
          <feather-icon icon="ListIcon" class="mr-50" />
          Logs
        </b-button>
      </router-link>

      <router-link
        :to="{
          name: 'amz-selling-partners-listings',
          params: { accountId: item.id },
        }"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-tour-finish mr-1 mb-1"
        >
          <feather-icon icon="ListIcon" class="mr-50" />
          Products
        </b-button>
      </router-link>

      <router-link
        :to="{
          name: 'amz-selling-partners-monitored',
          params: { accountId: item.id },
        }"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-tour-finish mr-1 mb-1"
        >
          <feather-icon icon="EyeIcon" class="mr-50" />
          Monitored
        </b-button>
      </router-link>

      <router-link
        :to="{ name: 'amz-sp-reports-ranges', params: { accountId: item.id } }"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-tour-finish mr-1 mb-1"
        >
          <feather-icon icon="FileTextIcon" class="mr-50" />
          Reports (Historical data)
        </b-button>
      </router-link>

      <router-link
        :to="{ name: 'amz-sp-orders-ranges', params: { accountId: item.id } }"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-tour-finish mr-1 mb-1"
        >
          <feather-icon icon="PackageIcon" class="mr-50" />
          Orders (Historical data)
        </b-button>
      </router-link>

      <router-link
        :to="{ name: 'amz-sp-events-ranges', params: { accountId: item.id } }"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="btn-tour-finish mr-1 mb-1"
        >
          <feather-icon icon="ActivityIcon" class="mr-50" />
          Events (Historical data)
        </b-button>
      </router-link>
    </b-card>

    <b-card class="text-center">
      <b-button
        variant="primary"
        class="btn-tour-finish"
        target="_blank"
        v-if="amazonUri"
        :href="amazonUri"
      >
        <feather-icon icon="PlusCircleIcon" class="mr-1" />
        <span class="align-middle">
          <b>Add new</b> or
          <b class="badge badge-light text-primary">Re-authorize</b> Account
        </span>
      </b-button>
    </b-card>
  </b-overlay>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BPagination,
  VBTooltip,
  BOverlay,
  BBadge,
  BAlert,
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardTitle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BAlert,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    ToastificationContent,
    BButton,
    BBadge,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      amazonUri: null,
      error: null,
      currentPage: 1,
      accounts: [],
      perPage: 25,
      fields: [
        "id",
        "name",
        "sellerId",
        "pages",
        "active",
        "authorizedAgo",
        "runAt",
        "actions",
      ],
      current: 1,
      rows: 0,
    };
  },

  mounted() {
    this.loadAccounts();
  },

  methods: {
    handlePageChange(value) {
      this.page = value;
      this.loadAccounts(this.page);
    },

    getAmazonUri() {
      this.$store.dispatch("amzSellingPartnerAccount/getState").then(
        (result) => {
          this.amazonUri = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.cd33a965-54b5-4cf7-93ee-c3e8f6e6a3c2&state=${result.data.value}&version=beta`;
        },
        (error) => {
          this.showToast(
            "Error",
            error.response?.data?.message ||
              error?.message ||
              "Error while loading amazonUri ",
            "danger"
          );
        }
      );
    },

    loadAccounts(page = 1) {
      this.loading = true;
      this.$store
        .dispatch("amzSellingPartnerAccount/fetchAll", page)
        .then(
          (result) => {
            this.accounts = result.data.docs;
            this.currentPage = result.data.page;
            this.rows = result.data.totalDocs;
            this.loading = false;

            this.getAmazonUri();
          },
          (error) => {
            this.loading = false;
            this.showToast(
              "Error",
              error.response?.data?.message ||
                error?.message ||
                "Error while loading items",
              "danger"
            );
            this.error = error.response?.data?.message || error?.message;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },

    remove(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-danger ",
          cancelButton: "btn btn-outline-primary  ml-1",
        },
        buttonsStyling: false,
      }).then(
        (result) => {
          if (result.value === true) {
            this.$store.dispatch("amzSellingPartnerAccount/remove", id).then(
              () => {
                this.showToast("Success", "Removed with success", "success");
                this.loadAccounts();
              },
              (error) => {
                this.showToast(
                  "Error",
                  err.response?.data?.message ||
                    err?.message ||
                    "Error while removing item",
                  "danger"
                );
              }
            );
          }
        },
        (error) => {
          console.log(err);
        }
      );
    },

    onStatusChange($event, id) {
      const data = {
        id,
        active: $event.target.value,
      };

      this.$store.dispatch("amzSellingPartnerAccount/update", data).then(
        () => {
          this.showToast("Success", "Item was updated successfully", "success");
        },
        (error) => {
          this.showToast(
            "Error",
            error.response?.data?.message ||
              error?.message ||
              "Error while updating item",
            "danger"
          );
        }
      );
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "InfoIcon",
          text,
          variant,
        },
      });
    },
  },
};
</script>
